import React from 'react'
import {Link} from 'react-router-dom'
import  brand from './img/lo.webp'


function Header() {
  return (
    <div className=''>
 



        <div className="container-fluid bg-primary px-5 d-none d-lg-block">
        <div className="row gx-0 align-items-center">
            <div className="col-lg-5 text-center text-lg-start mb-lg-0">
                <div className="d-flex">
                    <a href="#" className="text-white me-4"><i class="fas fa-envelope text-white me-2"></i>visa@gmail.com</a>
                    <a href="#" className="text-white me-0"><i class="fas fa-phone-alt text-white me-2"></i>+251979235206</a>
                </div>
            </div>
            <div className="col-lg-3 row-cols-1 text-center mb-2 mb-lg-0">
                
            </div>
            <div className="col-lg-4 text-center text-lg-end">
            <div class="d-inline-flex align-items-center" style={{height: '45px'}}>
            <a className="btn btn-sm btn-outline-light btn-square rounded-circle me-2 text-white bg-white" href=""><i class="fab fa-twitter fw-normal text-primary"></i></a>
            <a className="btn btn-sm btn-outline-light btn-square rounded-circle me-2 text-primary bg-white" href=""><i class="fab fa-facebook-f fw-normal text-primary"></i></a>
            <a className="btn btn-sm btn-outline-light btn-square rounded-circle me-2 text-primary bg-white" href=""><i class="fab fa-linkedin-in fw-normal text-primary"></i></a>
            <a className="btn btn-sm btn-outline-light btn-square rounded-circle me-2 bg-white" href=""><i class="fab fa-instagram fw-normal text-secondary"></i></a>
            <a className="btn btn-sm btn-outline-light btn-square rounded-circle bg-white" href=""><i class="fab fa-youtube fw-normal text-secondary"></i></a>
        </div>
            </div>
        </div>
    </div>
   

    <div className="nav-bar container  oo" >
    <div className="container-fluid">
        <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
        <Link to={'/'} class="navbar-brand p-0">
        <h1 class="display-5 text-white m-0"><img src={brand} class="img-fluid logonfc" alt=""/></h1>
       
    </Link>
      <Link type="button" className=" bg-black  kopa" data-toggle="collapse" data-target="#navbarCollapse">
      <span className="bi bi-list nananan text-white"></span>
    </Link>
    
            <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
       
    
               
                <div className="navbar-nav mr-auto text-white nab">
                    <Link to={'/'} className="nav-item nav-link  text-white" data-toggle="collapse" data-target="#navbarCollapse"> HOME</Link>
                    <Link to={'/about'} className="nav-item nav-link  text-white" data-toggle="collapse" data-target="#navbarCollapse" >ABOUT</Link>

                    <Link to={'/'} className="nav-item nav-link text-white" data-toggle="collapse" data-target="#navbarCollapse">SERVICES</Link>
                  
                    <div className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle  text-white" data-toggle="dropdown">PAGES</Link>
                    <div className="dropdown-menu ">
                        
                        <Link to={'/createblog'} className="dropdown-item" >DESTINATIONS </Link>
                        <Link to={'/adminblog'} className="dropdown-item">TOUR PACKAGES  </Link>
                        <Link to={'/faq'} className="dropdown-item" >FAQ</Link>
                        <Link to={'/gallery'} className="dropdown-item" >OUR GALLERY </Link>
                        <Link to={'/testimonialcontrol'} className="dropdown-item" >VISA CONSULTANCY </Link>
                    </div>
                </div>
                  
                    <Link to={'/blog'} className="nav-item nav-link text-white" data-toggle="collapse" data-target="#navbarCollapse">BLOG</Link>
                    <Link to={'/contact'} className="nav-item nav-link text-white lllll" data-toggle="collapse" data-target="#navbarCollapse">CONTACT</Link>
                </div>
                
                <div className="ml-auto mmmmm">
                <Link to={'/contact'} className="btn" >Contact us</Link>
                </div>
            </div>
        </nav>
    </div>
    </div>



    
    
    </div>
  )
}

export default Header