import React from 'react'
import { Link } from 'react-router-dom'
import Team from './Team'

function About() {
  return (
    <div>
 




    </div>
  )
}

export default About