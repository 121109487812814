import React from 'react'
import  carousel1 from './img/y2mate.com - ROPHNAN SHEGIYE ሮፍናን ሸግዬ_480p.gif'
import  brand from './img/brand-logo.png'
import  carousel2 from './img/carousel-2.jpg'
import Footer from './Footer'
import  service1 from './img/service-1.jpg'
import  service2 from './img/service-2.jpg'
import  service3 from './img/service-3.jpg'
import { Link } from 'react-router-dom';
import HomeAbout from './HomeAbout'
import Testimonial from './Testimonial'
import HomBlog from './HomBlog'

function Home() {
  return (
    <div >

  

    <div className="carousel-header">
            <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active"></li>
                    <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
                </ol>
                <div className="carousel-inner" role="listbox">
                    <div className="carousel-item active">
                        <img src={carousel1} class="img-fluid" alt=""/>
                        <div className="carousel-caption">
                            <div class="text-center p-4" style={{maxWidth: '900px'}}>
                                <h4 className="text-white text-uppercase fw-bold mb-3 mb-md-4 wow fadeInUp" data-wow-delay="0.1s">Solution For All Type Of Visas</h4>
                                <h1 className="display-1 text-capitalize text-white mb-3 mb-md-4 wow fadeInUp" data-wow-delay="0.3s">Immigration Process Starts Here!</h1>
                                <p className="text-white mb-4 mb-md-5 fs-5 wow fadeInUp" data-wow-delay="0.5s">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                </p>
                                <a class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5 wow fadeInUp" data-wow-delay="0.7s" href="#">More Details</a>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={carousel2} class="img-fluid" alt=""/>
                        <div className="carousel-caption">
                            <div class="text-center p-4" style={{maxWidth: '900px'}}>
                                <h5 className="text-white text-uppercase fw-bold mb-3 mb-md-4 wow fadeInUp" data-wow-delay="0.1s">Solution For All Type Of Visas</h5>
                                <h1 className="display-1 text-capitalize text-white mb-3 mb-md-4 wow fadeInUp" data-wow-delay="0.3s">Best Visa Immigrations Services</h1>
                                <p className="text-white mb-4 mb-md-5 fs-5 wow fadeInUp" data-wow-delay="0.5s">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                </p>
                                <a class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5 wow fadeInUp" data-wow-delay="0.7s" href="#">More Details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon bg-secondary wow fadeInLeft" data-wow-delay="0.2s" aria-hidden="false"></span>
                    <span className="visually-hidden-focusable">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                    <span className="carousel-control-next-icon bg-secondary wow fadeInRight" data-wow-delay="0.2s" aria-hidden="false"></span>
                    <span className="visually-hidden-focusable">Next</span>
                </button>
            </div>
        </div>




         <div class="modal fade" id="searchModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content rounded-0">
                    <div className="modal-header">
                        <h4 class="modal-title text-secondary mb-0" id="exampleModalLabel">Search by keyword</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body d-flex align-items-center">
                        <div className="input-group w-75 mx-auto d-flex">
                            <input type="search" class="form-control p-3" placeHolder="keywords" aria-describedby="search-icon-1"/>
                            <span id="search-icon-1" className="input-group-text p-3"><i class="fa fa-search"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <HomeAbout/>



   <div className="container-fluid counter-facts py-5">
            <div className="container py-5">
                <div className="row g-4">
                    <div className="col-12 col-sm-6 col-md-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="counter">
                            <div className="counter-icon">
                                <i className="fas fa-passport"></i>
                            </div>
                            <div className="counter-content">
                                <h3>Visa Categories</h3>
                                <div className="d-flex align-items-center justify-content-center">
                                    <span className="counter-value" data-toggle="counter-up">31</span>
                                    <h4 class="text-secondary mb-0" style={{fontWeight: '600', fontSize: '25px'}}>+</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="counter">
                            <div className="counter-icon">
                                <i className="fas fa-users"></i>
                            </div>
                            <div className="counter-content">
                                <h3>Team Members</h3>
                                <div className="d-flex align-items-center justify-content-center">
                                    <span className="counter-value" data-toggle="counter-up">377</span>
                                    <h4 class="text-secondary mb-0" style={{fontWeight: '600', fontSize: '25px'}}>+</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="counter">
                            <div className="counter-icon">
                                <i className="fas fa-user-check"></i>
                            </div>
                            <div className="counter-content">
                                <h3>Visa Process</h3>
                                <div className="d-flex align-items-center justify-content-center">
                                    <span className="counter-value" data-toggle="counter-up">4.9</span>
                                    <h4 class="text-secondary mb-0" style={{fontWeight: '600', fontSize: '25px'}}>K</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
                        <div className="counter">
                            <div className="counter-icon">
                                <i className="fas fa-handshake"></i>
                            </div>
                            <div className="counter-content">
                                <h3>Success Rates</h3>
                                <div className="d-flex align-items-center justify-content-center">
                                    <span className="counter-value" data-toggle="counter-up">98</span>
                                    <h4 class="text-secondary mb-0" style={{fontWeight: '600', fontSize: '25px'}}>%</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>





<div className="container-fluid service overflow-hidden pt-5">
            <div className="container py-5">
                <div className="section-title text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="sub-style">
                        <h5 className="sub-title text-primary px-3">Visa Categories</h5>
                    </div>
                    <h1 className="display-5 mb-4">Enabling Your Immigration Successfully</h1>
                    <p className="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat deleniti amet at atque sequi quibusdam cumque itaque repudiandae temporibus, eius nam mollitia voluptas maxime veniam necessitatibus saepe in ab? Repellat!</p>
                </div>
                <div className="row g-4">
                    <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item">
                            <div className="service-inner">
                                <div className="service-img">
                                    <img src={service1} class="img-fluid w-100 rounded" alt=""/>
                                </div>
                                <div className="service-title">
                                    <div className="service-title-name">
                                        <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                                            <a href="#" className="h4 text-white mb-0">Job Visa</a>
                                        </div>
                                        <a class="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                                    </div>
                                    <div className="service-content pb-4">
                                        <a href="#"><h4 className="text-white mb-4 py-3">Job Visa</h4></a>
                                        <div className="px-4">
                                            <p className="mb-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Mollitia fugit dolores nesciunt adipisci obcaecati veritatis cum, ratione aspernatur autem velit.</p>
                                            <a class="btn btn-primary border-secondary rounded-pill py-3 px-5" href="#">Explore More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-item">
                            <div className="service-inner">
                                <div className="service-img">
                                    <img src={service2} class="img-fluid w-100 rounded" alt=""/>
                                </div>
                                <div className="service-title">
                                    <div className="service-title-name">
                                        <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                                            <a href="#" className="h4 text-white mb-0">Business Visa</a>
                                        </div>
                                        <a class="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                                    </div>
                                    <div className="service-content pb-4">
                                        <a href="#"><h4 className="text-white mb-4 py-3">Business Visa</h4></a>
                                        <div className="px-4">
                                            <p className="mb-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Mollitia fugit dolores nesciunt adipisci obcaecati veritatis cum, ratione aspernatur autem velit.</p>
                                            <a class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5" href="#">Explore More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="service-item">
                            <div className="service-inner">
                                <div className="service-img">
                                    <img src={service3} class="img-fluid w-100 rounded" alt=""/>
                                </div>
                                <div className="service-title">
                                    <div className="service-title-name">
                                        <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                                            <a href="#" className="h4 text-white mb-0">Diplometic Visa</a>
                                        </div>
                                        <a class="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                                    </div>
                                    <div className="service-content pb-4">
                                        <a href="#"><h4 className="text-white mb-4 py-3">Diplometic Visa</h4></a>
                                        <div className="px-4">
                                            <p className="mb-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Mollitia fugit dolores nesciunt adipisci obcaecati veritatis cum, ratione aspernatur autem velit.</p>
                                            <a class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5" href="#">Explore More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item">
                            <div className="service-inner">
                                <div className="service-img">
                                    <img src={service1} class="img-fluid w-100 rounded" alt=""/>
                                </div>
                                <div className="service-title">
                                    <div className="service-title-name">
                                        <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                                            <a href="#" className="h4 text-white mb-0">Students Visa</a>
                                        </div>
                                        <a class="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                                    </div>
                                    <div className="service-content pb-4">
                                        <a href="#"><h4 className="text-white mb-4 py-3">Students Visa</h4></a>
                                        <div className="px-4">
                                            <p className="mb-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Mollitia fugit dolores nesciunt adipisci obcaecati veritatis cum, ratione aspernatur autem velit.</p>
                                            <a class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5" href="#">Explore More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-item">
                            <div className="service-inner">
                                <div className="service-img">
                                    <img src={service2} class="img-fluid w-100 rounded" alt=""/>
                                </div>
                                <div className="service-title">
                                    <div className="service-title-name">
                                        <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                                            <a href="#" className="h4 text-white mb-0">Residence Visa</a>
                                        </div>
                                        <a class="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                                    </div>
                                    <div className="service-content pb-4">
                                        <a href="#"><h4 className="text-white mb-4 py-3">Residence Visa</h4></a>
                                        <div className="px-4">
                                            <p className="mb-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Mollitia fugit dolores nesciunt adipisci obcaecati veritatis cum, ratione aspernatur autem velit.</p>
                                            <a class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5" href="#">Explore More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="service-item">
                            <div className="service-inner">
                                <div className="service-img">
                                    <img src={service3} class="img-fluid w-100 rounded" alt=""/>
                                </div>
                                <div className="service-title">
                                    <div className="service-title-name">
                                        <div className="bg-primary text-center rounded p-3 mx-5 mb-4">
                                            <a href="#" className="h4 text-white mb-0">Tourist Visa</a>
                                        </div>
                                        <a class="btn bg-light text-secondary rounded-pill py-3 px-5 mb-4" href="#">Explore More</a>
                                    </div>
                                    <div className="service-content pb-4">
                                        <a href="#"><h4 className="text-white mb-4 py-3">Tourist Visa</h4></a>
                                        <div className="px-4">
                                            <p className="mb-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Mollitia fugit dolores nesciunt adipisci obcaecati veritatis cum, ratione aspernatur autem velit.</p>
                                            <a class="btn btn-primary border-secondary rounded-pill text-white py-3 px-5" href="#">Explore More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>






        <div className="container-fluid features overflow-hidden py-5">
            <div className="container">
                <div className="section-title text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="sub-style">
                        <h5 className="sub-title text-primary px-3">Why Choose Us</h5>
                    </div>
                    <h1 className="display-5 mb-4">Offer Tailor Made Services That Our Client Requires</h1>
                    <p className="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat deleniti amet at atque sequi quibusdam cumque itaque repudiandae temporibus, eius nam mollitia voluptas maxime veniam necessitatibus saepe in ab? Repellat!</p>
                </div>
                <div className="row g-4 justify-content-center text-center">
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item text-center p-4">
                            <div className="feature-icon p-3 mb-4">
                                <i className="fas fa-dollar-sign fa-4x text-primary"></i>
                            </div>
                            <div className="feature-content d-flex flex-column">
                                <h5 className="mb-3">Cost-Effective</h5>
                                <p className="mb-3">Dolor, sit amet consectetur adipisicing elit. Soluta inventore cum accusamus,</p>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="feature-item text-center p-4">
                            <div className="feature-icon p-3 mb-4">
                                <i className="fab fa-cc-visa fa-4x text-primary"></i>
                            </div>
                            <div className="feature-content d-flex flex-column">
                                <h5 className="mb-3">Visa Assistance</h5>
                                <p className="mb-3">Dolor, sit amet consectetur adipisicing elit. Soluta inventore cum accusamus,</p>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="feature-item text-center p-4">
                            <div className="feature-icon p-3 mb-4">
                                <i className="fas fa-atlas fa-4x text-primary"></i>
                            </div>
                            <div className="feature-content d-flex flex-column">
                                <h5 className="mb-3">Faster Processing</h5>
                                <p className="mb-3">Dolor, sit amet consectetur adipisicing elit. Soluta inventore cum accusamus,</p>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
                        <div className="feature-item text-center p-4">
                            <div className="feature-icon p-3 mb-4">
                                <i className="fas fa-users fa-4x text-primary"></i>
                            </div>
                            <div className="feature-content d-flex flex-column">
                                <h5 className="mb-3">Direct Interviews</h5>
                                <p className="mb-3">Dolor, sit amet consectetur adipisicing elit. Soluta inventore cum accusamus,</p>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <a class="btn btn-primary border-secondary rounded-pill py-3 px-5 wow fadeInUp" data-wow-delay="0.1s" href="#">More Features</a>
                    </div>
                </div>
            </div>
        </div>

        <HomBlog/>

<Footer/>
    </div>
  )
}

export default Home