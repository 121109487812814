import React from 'react'
import  service2 from './img/service-2.jpg'
function Gallery() {
  return (
    <div>
    <section class="site-section" id="next-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-4 item">
            <a href="images/sq_img_1.jpg" className="item-wrap fancybox" data-fancybox="gallery2">
              <span className="icon-search2"></span>
              <img class="img-fluid" src={service2} alt=''/>
            </a>
          </div>
          <div className="col-md-6 col-lg-4 item">
            <a href="images/sq_img_2.jpg" className="item-wrap fancybox" data-fancybox="gallery2">
              <span className="icon-search2"></span>
              <img class="img-fluid" src={service2} alt=''/>
            </a>
          </div>
          <div className="col-md-6 col-lg-4 item">
            <a href="images/sq_img_3.jpg" className="item-wrap fancybox" data-fancybox="gallery2">
              <span className="icon-search2"></span>
              <img class="img-fluid" src={service2} alt=''/>
            </a>
          </div>

          <div className="col-md-6 col-lg-4 item">
            <a href="images/sq_img_4.jpg" className="item-wrap fancybox" data-fancybox="gallery2">
              <span className="icon-search2"></span>
              <img class="img-fluid" src={service2} alt=''/>
            </a>
          </div>
          <div className="col-md-6 col-lg-4 item">
            <a href="images/sq_img_5.jpg" className="item-wrap fancybox" data-fancybox="gallery2">
              <span className="icon-search2"></span>
              <img class="img-fluid" src={service2} alt=''/>
            </a>
          </div>
          <div className="col-md-6 col-lg-4 item">
            <a href="images/sq_img_6.jpg" className="item-wrap fancybox" data-fancybox="gallery2">
              <span className="icon-search2"></span>
              <img class="img-fluid" src={service2} alt=''/>
            </a>
          </div>

         

        </div>
      </div>
    </section>
    
    
    </div>
  )
}

export default Gallery