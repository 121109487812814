import React, { useEffect, useState } from 'react'
import './SingleBlog.css';

import axios from 'axios';
import  passport from './img/lo.webp'
import { Link, useParams } from 'react-router-dom';

function BlogDetail() {
    const [data, setdata] = useState([]);
    const [latest, setlatest] = useState([]);
    const { id } = useParams();
    const [comment, setcomment] = useState({
      name:'',
      email:'',
      text:''
    });
    const [commentfetchd, setcommentfetchd] = useState([]);

    useEffect(() => {
        axios.get('http://localhost:8081/detail/' + id)
          .then(res => {
            if (res.data.status === "success") {
              setdata(res.data.Result)
             
            }
          })
          .catch(err => console.log(err))
      }, [id]);



      useEffect(() => {
        axios.get("http://localhost:8081/blogfetch")
        .then(res=>{
    if (res.data.status==="success") {
      
        setlatest(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 5))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);

    

    const commenthandle=(e)=>{
      e.preventDefault()
      
    console.log(Date());
      console.log(comment.name);
      axios.post('http://localhost:8081/comment',{name:comment.name,email:comment.email,text:comment.text,id:id,date:Date().slice(10, 10)})
      .then(res=>{
        if(res.data.status==="success"){
     
      
      window.location.reload();
      setcomment({name:"",email:"",text:""
      })
    
        }
      })
      .catch(err=>console.log(err))
      
          }

          useEffect(() => {
            axios.get("http://localhost:8081/commentfetch/"+id)
            .then(res=>{
        if (res.data.status==="success") {
          console.log(res.data.Result);
          setcommentfetchd(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 5))
            
        }
                
            })
            .catch(err=>console.log(err))
           
        }, [id]);
    


  return (
    <div>
    
	
    

  <section id="blog" class="blog">
  <div class="container" data-aos="fade-up">

    <div class="row">

      <div class="col-lg-8 entries">

      {data.map((detail,i)=>{
		return	  <article class="entry entry-single" key={i}>

          <div class="entry-img">
            <img src={'http://localhost:8081/images/' + detail.image} alt="" class="img-fluid"/>
          </div>

          <h2 class="entry-title">
            <Link className='dec text-decoration-none text-dark'>{detail.subject}</Link>
          </h2>

          <div class="entry-meta">
            <ul>
              
              <li class="d-flex align-items-center"><i class="bi bi-clock text-warning"></i> <Link className='dec text-decoration-none '><time datetime="2020-01-01">{detail.date}</time></Link></li>
             
            </ul>
          </div>

          <div class="entry-content">
            <p>
             {detail.detail}
            </p>

           

         

         {detail.detail2 &&   <p>
              {detail.detail2}
            </p>}


         

          </div>

         
        </article>

    })}

        <div className="comments">

              <h4 className="comments-count">{commentfetchd.length} Comments</h4>

              {commentfetchd.map((cc,i)=>{ 
                return <div div id="comment-1" className="comment" key={i}>
                <div className="d-flex">
                   <div class="comment-img"><img src={passport} alt=""/></div>
                  <div>
                    <h5><Link className='text-decoration-none'>{cc.name}</Link></h5>
                    <time dateTime="2020-01-01">{cc.date}</time>
                    <p>
                     {cc.ctext}
                    </p>
                  </div>
                </div>
              </div>})}

           
              

           

              <div className="reply-form">

                <h4>Leave Your Comment</h4>
                <p>Your email address will not be published. Required fields are marked * </p>
                <form onSubmit={commenthandle}>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input name="name" type="text" class="form-control"
                      value={comment.name} onChange={e => setcomment({ ...comment, name: e.target.value })}
                      placeHolder="Full Name*"/>
                    </div>
                    <div className="col-md-6 form-group">
                      <input name="email" type="text" class="form-control"
                      value={comment.email} onChange={e => setcomment({ ...comment, email: e.target.value })}
                      placeHolder="Your Email*"/>
                    </div>
                  </div>
                 
                  <div className="row">
                    <div className="col form-group">
                      <textarea name="comment" class="form-control"
                      value={comment.text} onChange={e => setcomment({ ...comment, text: e.target.value })}
                      placeHolder="Your Comment*"></textarea>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-success">Post Comment</button>

                </form>

              </div>

            </div> 

      </div>
      <div class="col-lg-4 mr-0 px-0">

      <div class="sidebar mx-0">

        


        <h3 class="sidebar-title text-dark">Recent Posts</h3>
        <div class="sidebar-item recent-posts">
        {latest.map((blog,i)=>{
          return   <div class="post-item clearfix" key={i}>
          <Link to={`/blogdetail/${blog.id}`} className='text-decoration-none '>
             <img src={'http://localhost:8081/images/' + blog.image} alt=""/>
             <h4><h6 class="text-decoration-none text-dark">{blog.subject}</h6></h4>
             <time datetime="2020-01-01" >{blog.date}</time>
             </Link>
           </div>
        }) }
      
         

  

   

      </div>

    </div>

  </div>


    </div>

  </div>
</section>


    
    </div>
  )
}

export default BlogDetail