import React from 'react'
import  service2 from './img/service-2.jpg'
function Faq() {
  return (
    <div>
    
    <section class="site-section" id="accordion">
      <div className="container">
        
        <div className="row accordion justify-content-center block__76208">
          <div className="col-lg-6">
            <img src={service2} alt="" className="img-fluid rounded"/>
          </div>
          <div className="col-lg-5 ml-auto">
            <div className="accordion-item">
              
                <a className="btn-block h4" data-toggle="collapse" href="#collapseFive" role="button" aria-expanded="true" aria-controls="collapseFive">       <i className="bi bi-joystick kilolo h3 mx-2"></i>What is the name of your company<span class="icon"></span></a>
             
              <div id="collapseFive" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="body-text">
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur quae cumque perspiciatis aperiam accusantium facilis provident aspernatur nisi optio debitis dolorum, est eum eligendi vero aut ad necessitatibus nulla sit labore doloremque magnam! Ex molestiae, dolor tempora, ad fuga minima enim mollitia consequuntur, necessitatibus praesentium eligendi officia recusandae culpa tempore eaque quasi ullam magnam modi quidem in amet. Quod debitis error placeat, tempore quasi aliquid eaque vel facilis culpa voluptate.</p>
                </div>
              </div>
            </div> 

            <div className="accordion-item">
              
                <a className="btn-block h4" data-toggle="collapse" href="#collapseSix" role="button" aria-expanded="false" aria-controls="collapseSix">       <i class="bi bi-joystick kilolo h3 mx-2"></i>How much pay for 3  months?<span class="icon"></span></a>
            
              <div id="collapseSix" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="body-text">
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel ad laborum expedita. Nostrum iure atque enim quisquam minima distinctio omnis, consequatur aliquam suscipit, quidem, esse aspernatur! Libero, excepturi animi repellendus porro impedit nihil in doloremque a quaerat enim voluptatum, perspiciatis, quas dignissimos maxime ut cum reiciendis eius dolorum voluptatem aliquam!</p>
                </div>
              </div>
            </div> 

            <div className="accordion-item">
             
                <a className="btn-block h4" data-toggle="collapse" href="#collapseSeven" role="button" aria-expanded="false" aria-controls="collapseSeven">       <i class="bi bi-joystick kilolo h3 mx-2"></i>Do I need to register?  <span class="icon"></span></a>
            
              <div id="collapseSeven" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="body-text">
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel ad laborum expedita. Nostrum iure atque enim quisquam minima distinctio omnis, consequatur aliquam suscipit, quidem, esse aspernatur! Libero, excepturi animi repellendus porro impedit nihil in doloremque a quaerat enim voluptatum, perspiciatis, quas dignissimos maxime ut cum reiciendis eius dolorum voluptatem aliquam!</p>
                </div>
              </div>
            </div> 

            <div className="accordion-item">
     
                <a className="btn-block h4" data-toggle="collapse" href="#collapseEight" role="button" aria-expanded="false" aria-controls="collapseEight"> <i class="bi bi-joystick kilolo h3 mx-2"></i>Who should I contact in case of support.<span class="icon"></span></a>
             
              <div id="collapseEight" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="body-text">
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel ad laborum expedita. Nostrum iure atque enim quisquam minima distinctio omnis, consequatur aliquam suscipit, quidem, esse aspernatur! Libero, excepturi animi repellendus porro impedit nihil in doloremque a quaerat enim voluptatum, perspiciatis, quas dignissimos maxime ut cum reiciendis eius dolorum voluptatem aliquam!</p>
                </div>
              </div>
            </div> 

          </div>

          
        </div>
      </div>
    </section>
    
    
    </div>
  )
}

export default Faq