import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function HomBlog() {

    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("http://localhost:8081/blogfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 3))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);
   
  return (
    <div>
    
    {data.length>0 &&   <section id="recent-blog-posts" className="recent-blog-posts">
      <div className="container" data-aos="fade-up">

    
    
      <div className="section-title text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="sub-style">
          <h5 className="sub-title text-primary px-3">Recent News</h5>
      </div>
      <h1 className="display-5 mb-4">Offer Tailor Made Services That Our Client Requires</h1>
      <p className="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat deleniti amet at atque sequi quibusdam cumque itaque repudiandae temporibus, eius nam mollitia voluptas maxime veniam necessitatibus saepe in ab? Repellat!</p>
  </div>

      <div className="row gy-5">

       {data.map((blog,i)=>{
        return     <div class="col-xl-4 col-md-6" key={i}>
        <div class="post-item position-relative h-100">

          <div class="post-img position-relative overflow-hidden">
            <img src={'http://localhost:8081/images/' + blog.image}  class="img-fluid rounded" alt=""/>
            <span class="d-flex align-items-center "><i class="bi bi-clock text-warning mx-2"></i> <Link className='dec text-decoration-none text-balck'><time datetime="2020-01-01" className=''>{blog.date}</time></Link></span>
          </div>
<hr/>
          <div class=" d-flex flex-column ">

          <Link to={`/blogdetail/${blog.id}`} class="text-decoration-none readmore stretched-link"> <h4 className="post-title text-dark">{blog.subject}</h4></Link>

           
          

          </div>

        </div>
      </div>})}

       
      </div>

      </div>
    </section>}
   
    
    </div>
  )
}

export default HomBlog