
import './App.css';
import { Routes,Route } from 'react-router-dom';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from './Pages/Header';
import Home from './Pages/Home';
import Blog from './Pages/Blog';
import Contactus from './Pages/Contact_us';


import Footer from './Pages/Footer';
import About from './Pages/About';
import BlogDetail from './Pages/BlogDetail';
import Gallery from './Pages/Gallery';
import Faq from './Pages/Faq';


function App() {
  return (
    
    <Routes>
      <Route path='/' element={<div><Header/><Home/></div>} />
      <Route path='/blog' element={<div><Header/><Blog/></div>} />
      <Route path='/contact' element={<div><Header/><Contactus/></div>} />
      <Route path='/faq' element={<div><Header/><Faq/></div>} />
      <Route path='/gallery' element={<div><Header/><Gallery/></div>} />
      <Route path='/blogdetail/:id' element={<div><Header/><BlogDetail/><Footer/></div>}/>
      <Route path='/about' element={<div><Header/><About/><Footer/></div>} />

    </Routes>
  
  );
}

export default App;
